<template>
  <v-dialog
    :value="dialog"
    content-class="elevation-0"
    :width="defaultDialogSize[$vuetify.breakpoint.name]"
    persistent
    @keydown.enter.prevent="enterPressed"
    @keydown.esc.prevent="closeDialog"
  >
    <v-card>
      <v-row class="flex justify-space-between ma-0">
        <v-card-title>
          {{ dialogTitle }}
        </v-card-title>

        <v-btn
          text
          x-small
          class="mt-3 mr-2 pa-0 no-background-hover"
          elevation="0"
          @click.prevent="$emit('action-canceled')"
        >
          <v-icon
            color="grey darken-1"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-row>

      <v-card-text>
        <slot />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="grey darken-1"
          text
          @click.prevent="$emit('action-canceled')"
        >
          {{ cancelText }}
        </v-btn>

        <v-btn
          color="blue"
          text
          @click.prevent="$emit('action-confirmed')"
        >
          {{ actionText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    fetch: {
      type: Function,
      default: null
    },

    dialog: {
      type: Boolean,
      required: true
    },

    dialogTitle: {
      type: String,
      default: null
    },

    actionText: {
      type: String,
      default: null
    },

    cancelText: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      defaultDialogSize: {
        xl: '33.33%',
        lg: '33.33%',
        md: '50%',
        sm: '66.66%',
        xs: '100%'
      }
    }
  },

  created () {
    if (this.fetch) {
      this.fetch()
    }
  },

  methods: {
    enterPressed () {
      this.$emit('action-confirmed')
    },

    closeDialog () {
      this.$emit('close-dialog')
    }
  }
}
</script>
