<template>
  <index-modal
    :view-config="viewConfig"
    :dialog="dialog"
    :reset-dialog="resetDialog"
    :dialog-title="viewConfig.form ? viewConfig.form.title : ''"
    :action-text="viewConfig.form ? viewConfig.form.action_button_text : ''"
    :cancel-text="viewConfig.form ? viewConfig.form.cancel_button_text : ''"
    :fetch="fetch"
    @action-confirmed="sendSms"
    @action-canceled="resetDialog"
  >
    <v-textarea
      :value="smsContent"
      :error-messages="validationErrors.sms_content || []"
      outlined
      rows="3"
      :label="getLabel('sms_content')"
      @input="$event => handleSmsContentInput($event, 'sms_content')"
    />
    <v-alert
      v-if="checkIfAtLeastOnePhoneNumberIsPresent"
      text
      shaped
      outlined
      dense
      type="error"
    >
      {{ phoneNumbersValidationErrors }}
    </v-alert>
    <v-text-field
      v-for="(phoneNumber, index) in phoneNumbers"
      :key="index"
      :value="phoneNumber"
      class="py-0"
      :label="getLabel('phone_numbers')"
      :append-outer-icon="'mdi-close'"
      :error-messages="validationErrors[`phone_numbers.${index}`]"
      outlined
      dense
      @input="$event => handlePhoneNumberInput(index, $event)"
      @click:append-outer="removeNumber(index)"
    />
    <div class="d-flex justify-center">
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="indigo"
        @click="addNewNumber"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
  </index-modal>
</template>

<script>
import IndexModal from '@/global/components/modals/IndexModal'
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters,
  mapActions
} = createNamespacedHelpers('sms/send-sms')
export default {
  name: 'SendSmsView',

  components: {
    IndexModal
  },

  data () {
    return {}
  },

  computed: {
    ...mapGetters([
      'dialog',
      'phoneNumbers',
      'smsContent',
      'viewConfig',
      'validationErrors'
    ]),

    phoneNumbersValidationErrors () {
      return 'phone_numbers' in this.validationErrors
        ? this.validationErrors.phone_numbers[0]
        : ''
    },

    checkIfAtLeastOnePhoneNumberIsPresent () {
      return !this.phoneNumbers.length &&
        'phone_numbers' in this.validationErrors &&
        this.validationErrors.phone_numbers.length
    }
  },

  watch: {
    phoneNumbers: {
      deep: true,
      handler (phoneNumbers) {
        if (phoneNumbers.length) {
          this.resetValidationErrors('phone_numbers')
        }
      }
    }
  },

  methods: {
    ...mapActions([
      'fetch',
      'resetDialog',
      'addNumber',
      'updateNumber',
      'updateSmsContent',
      'removeNumber',
      'sendSms',
      'resetValidationErrors',
      'resetPhoneNumbersValidationErrors'
    ]),

    handleSmsContentInput (event, key) {
      this.updateSmsContent(event)
      this.resetValidationErrors(key)
    },

    handlePhoneNumberInput (index, event) {
      this.updateNumber({ index: index, value: event })
      this.resetPhoneNumbersValidationErrors(index)
    },

    addNewNumber () {
      this.addNumber('')
    },

    getLabel (field) {
      return this.viewConfig.form && this.viewConfig.form[field].label
    }
  }
}
</script>
